<template>
  <div class="booking-main">
    <div class="dashboard-header booking-header"></div>
    <!-- <div class="dashboard-header"></div> -->
    <div class="booking-body">
      <h4>All Tickets</h4>
      <div class="dashboard-header booking-header">
        <div class="bh-form">
          <router-link to="/new-ticket" tag="button" class="btn btn-primary"
            >Create new ticket</router-link
          >
        </div>
      </div>
      <div class="booking-fold">
        <div class="booking-table table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>No</th>
                <th>Title</th>
                <th>Message</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody v-if="rows.length > 0 && !isfetchingTicket">
              <router-link
                v-for="(ticket, index) in rows"
                v-bind:key="index"
                :to="{ name: 'ticketShow', params: { id: ticket.id } }"
                tag="tr"
                style="cursor: pointer"
              >
                <td>
                  {{ tableInfo.currentPage * tableInfo.perPage - tableInfo.perPage + index + 1 }}
                </td>
                <td v-if="ticket.title && ticket.title.length < 25">{{ ticket.title }}</td>
                <td v-else-if="ticket.title">{{ ticket.title.substring(0, 25) + '..' }}</td>
                <td v-if="ticket.message && ticket.message.length < 25">{{ ticket.message }}</td>
                <td v-else-if="ticket.message">{{ ticket.message.substring(0, 25) + '..' }}</td>
                <td>{{ ticket.status_name }}</td>
                <td>{{ moment(ticket.created_at).format('D MMM YYYY') }}</td>
              </router-link>
            </tbody>
            <tbody v-if="isfetchingTicket">
              <tr>
                <td colspan="100%" style="text-align: center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="rows.length == 0 && !isfetchingTicket">
              <tr>
                <td colspan="100%" style="text-align: center">
                    <img
                      class="noDataImage"
                      :src="require('@/assets/images/nodata.svg')"
                      alt=""
                    /><br /><br />
                    <b>No ticket found</b>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination
            v-if="rows.length > 0 && !isfetchingTicket"
            :perPage="serverParams.perPage"
            :totalRows="tableInfo.total"
            :value="serverParams.page"
            v-on:pagechanged="pagechanged"
            v-on:perPagechanged="perPagechanged"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import pagination from '@/components/pagination.vue';

export default {
  name: 'Support',
  components: { pagination },
  data() {
    return {
      rows: [],
      isfetchingTicket: false,
      tableInfo: {},
      totalRecords: 0,
      serverParams: {
        search: '',
        page: 1,
        perPage: 10,
      },
    };
  },
  created() {
    this.getticket();
  },
  methods: {
    moment,
    getticket() {
      this.isfetchingTicket = true;
      this.$http
        .get('/customer/ticket/list', {
          params: { ...this.serverParams },
        })
        .then((res) => {
          const { collection, ...info } = res.data.data;
          this.rows = collection;
          this.tableInfo = info;
        })
        .catch((error) => {
          this.rows = [];
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isfetchingTicket = false;
        });
    },
    pagechanged(e) {
      this.serverParams.page = Number(e);
      this.getticket();
    },
    perPagechanged(e) {
      this.serverParams.perPage = Number(e);
      this.getticket();
    },
  },
};
</script>
